import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ApiUrl } from '../../components/API/Api';
import { Button, Modal } from 'react-bootstrap';

const Seminarians = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedPriest, setSelectedPriest] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/get/kurnooldiocese/preist/7`);
                setData(response?.data?.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleShow = (priest) => {
        setSelectedPriest(priest);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedPriest(null);
    };

    return (
        <>
            <div className="container">
                <h3 className="text-center mt-3 mb-3">Meet our Seminarians</h3>
                <div className="row">
                    <div className="col-md-12">
                        <h4>What is a Seminary?</h4>
                        <p className="text-justify">
                            A seminary is a formation institution dedicated to forming young men into effective and compassionate priests who serve the people of God later on. Seminarians from the Diocese of Kurnool are currently progressing through various stages of formation. Seminary formation is structured around four key areas: Spiritual, Intellectual, Pastoral, and Human development. These serve as the foundational elements in preparing men for the priesthood.
                        </p>
                    </div>
                    <div className="col-md-12">
                        <h4>How many years does one  take to become a priest?</h4>
                        <p className="text-justify">
                            The journey to priesthood typically takes about 10 to 12 years, depending on when and where one begins. A young man entering seminary right after high school will first spend 4 years in Minor Seminary (which includes 1 year of orientation and 2 years of junior college and one year of Spiritual orientation). He then moves on to Major Seminary, where he will spend 2 or three years of studying philosophy and completing his undergraduate degree. After this, there is a 1-year regency period, followed by 4 years of study at a theological seminary, focusing on Scripture, Theology, Church History, and other related disciplines.
                        </p>
                    </div>
                    <div className="col-md-12 bg-light">
                        <div className="grey-bg p-3">
                            <h4 className="text-center">Our Seminarians and New Priests</h4>
                            {loading ? (
                                <p className="text-center font-weight-bold">
                                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                                </p>
                            ) : (
                                <div className="row">
                                    {data?.length > 0 ? (
                                        data?.map((priest, index) => (
                                            <div key={index} className="col-md-2 text-center">
                                                <img
                                                    src={priest?.media_url || 'images/all-img/Catholic-Priest.png'}
                                                    className="rounded-circle img-fluid my-2"
                                                    alt={priest?.name}
                                                    onClick={() => handleShow(priest)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <p className="seminarian" onClick={() => handleShow(priest)} style={{ cursor: 'pointer' }}>{priest?.name}</p>
                                                <p className="sem-text">{priest?.year_of_study}</p>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-center">No seminarians found</p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <h4>Support a Seminarian or Young Priest</h4>
                        <p className="text-justify">
                            "A Priest is not a his own" Fulton Sheen.
                        </p>
                        <p className="text-justify">
                            A priest is called for others in the church to administer Sacraments, lead the people of God in faith in the parishes and promote spirituality and religiosity.
                        </p>
                        <p className="text-justify">
                            A vocation is a gift from God, given to those He calls by name. Along with this calling comes the responsibility to fulfill the mission He entrusts to them. As baptized members of the Church, we all share the duty to support this mission through our prayers, efforts, and financial contributions. We kindly ask for your generous assistance in supporting our seminarians and young priests—through both your prayers and financial support—to foster more vocations to the Priesthood and Religious life.
                        </p>
                    </div>

                    <div className="col-md-12 mt-3">
                        <h4>For supporting our seminarians</h4>
                        <h6>please contact:</h6>
                        <br />
                        <p className="text-justify">
                            <a href="mailto:dioceseofkurnoolsociety@gmail.com">
                                <i className="fa fa-envelope mr-2"></i>
                                dioceseofkurnoolsociety@gmail.com
                            </a>
                            <br />
                            <br />
                            <a href="mailto:secretaryknl@gmail.com">
                                <i className="fa fa-envelope mr-2"></i>
                                secretaryknl@gmail.com
                            </a>
                        </p>
                    </div>
                </div>
                <Modal show={showModal} onHide={handleClose} centered size="lg" backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedPriest?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex">
                        <div className="flex-grow-1">
                            <p><strong>Date of Birth:</strong> {selectedPriest?.date_of_birth || 'N/A'}</p>
                            <p><strong>Year of Study:</strong> {selectedPriest?.year_of_study || 'N/A'}</p>
                            <p><strong>Place of Study:</strong> {selectedPriest?.place_of_study || 'N/A'}</p>
                            <p><strong>Email:</strong> {selectedPriest?.email || 'N/A'}</p>
                            <p><strong>Phone:</strong> {selectedPriest?.phone || 'N/A'}</p>
                        </div>
                        <div className="flex-shrink-0 ms-3 text-end">
                            <img src={selectedPriest?.media_url || 'images/all-img/Catholic-Priest.png'} className="img-thumbnail rounded-circle" style={{ width: '100px', height: '100px' }} alt={selectedPriest?.name} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose} size='sm'>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default Seminarians;
