import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { ApiUrl } from '../../components/API/Api';
import 'jspdf-autotable';

const DetailParish = () => {
  const [parishData, setParishData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const parishesUrlId = searchParams.get("di");
  const categoryparishId = searchParams.get("from");
  console.log(parishesUrlId);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await axios.get(`${ApiUrl}/get/kurnooldiocese/parish/${parseInt(categoryparishId)}`);
        debugger
        const resdata = response?.data?.data;
        const filteredObject = resdata?.find(item => item.id === parseInt(parishesUrlId));

        setParishData(filteredObject);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [categoryparishId, parishesUrlId]);

  return (
    <>
      <div className="container mt-3">
        {loading && <p className="text-center mt-5 font-weight-bold">Loading...</p>}
        {error && <p>Error: {error.message}</p>}
        {parishData && (
          <>
            <nav aria-label="breadcrumb" className="mb-3">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item"><a href="/parishes">Parishes</a></li>
                <li className="breadcrumb-item active" aria-current="page">{parishData.parish_name}</li>
              </ol>
            </nav>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <h5 className="heading text-center mb-4 ml-1" style={{ fontFamily: "Montserrat" }}>{parishData.parish_name}</h5>
                    <dd dangerouslySetInnerHTML={{ __html: parishData.history }} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <br />
    </>
  );
};

export default DetailParish;
